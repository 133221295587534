import React from "react"
import Layout from "../components/layout"

const Beecon = () => {
  return (
    <Layout>
      <span>WIP</span>
    </Layout>
  )
}

export default Beecon
